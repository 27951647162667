import React from 'react'
import PropTypes from 'prop-types';
import styles from './TextField.module.scss';

const TextField = (props) => {
  return(
    <div className={styles.formGroup}>
      <input 
        type={props.type} 
        className={styles.formControl}
        id={props.id}
        name={props.id} 
        placeholder={props.placeholder}
        value={props.value}
        onChange={props.onChange}
        {...props}
      />
      <label htmlFor={props.id}>{props.placeholder}</label>
    </div>
  );
}

export default TextField;

TextField.propTypes = {
  type: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
}