import React from 'react'
import PropTypes from 'prop-types';
import styles from './SubmitButton.module.scss';

import loading from './../../../images/icons/loading.svg';

const SubmitButton = ({ sending }) => {
  return(
    <div className={styles.buttonWithLoader + ' ' + (sending ? styles.disabled : '')}>
      <div role="status" className={styles.status}>
        <img src={loading} alt="Laden..." />
      </div>
      <input 
        type="Submit" 
        defaultValue="Verzenden" 
        className="btn btn-outline-secondary" 
        disabled={(sending ? true : false)}
      />
    </div>
  )
}

export default SubmitButton;

SubmitButton.propTypes = {
  sending: PropTypes.bool.isRequired
}