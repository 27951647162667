import React from 'react'
import PropTypes from 'prop-types';
import styles from './../TextField/TextField.module.scss';

const TextField = (props) => {
  return(
    <div className={`${styles.formGroup} ${styles.formGroupTextArea}`}>
      <textarea 
        className={styles.formControl}
        id={props.id}
        name={props.id} 
        placeholder={props.placeholder}
        value={props.value}
        onChange={props.onChange}
        {...props}
      ></textarea>
      <label htmlFor={props.id}>{props.placeholder}</label>
    </div>
  );
}

export default TextField;

TextField.propTypes = {
  id: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
}