import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import SEO from "components/Global/SEO/";
import TextField from 'components/Global/TextField';
import TextArea from 'components/Global/TextArea';
import SubmitButton from 'components/Global/SubmitButton';
import { isValidEmail } from 'helpers/utils';

const encode = (data) => {
  return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&");
}

const Contact = (params) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [captcha, setCaptcha] = useState();
  const [sending, setSending] = useState(false);
  const [serverMessage, setServerMessage] = useState("");
  const [alertClass, setAlertClass] = useState("");
  const recaptchaRef = React.createRef();

  const handleSubmit = e => {
    e.preventDefault();
    console.log(captcha);
    if(name === "" || 
      email === "" ||
      message === "" ||
      captcha === undefined
    ) {
      setServerMessage(
        <>
          Er zijn fouten ontdekt in het formulier. Los onderstaande problemen op en probeer opnieuw.
          <ul className="pl-3">
            {!name && ( <li>Er is geen naam ingevuld</li>)}
            {!email && ( <li>Er is geen e-mailadres ingevuld</li>)}
            {!isValidEmail(email) && ( <li>Er is geen geldig e-mailadres ingevuld</li>)}
            {!message && ( <li>Er is geen bericht ingevuld</li>)}
            {!captcha && (<li>Het 'Ik ben geen robot veld' is niet aangevinkt</li>)}
          </ul>
        </>
      );
      setAlertClass('alert-danger');
      return false;
    }

    if(!isValidEmail(email)) {
      setServerMessage(
        <>
          Er zijn fouten ontdekt in het formulier. Los onderstaande problemen op en probeer opnieuw.
          <ul className="pl-3">
            <li>Er is geen geldig e-mailadres ingevuld</li>
          </ul>
        </>
      );
      setAlertClass('alert-danger');
      return false;
    }

    setSending(true);   

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ 
        "form-name": "contact",
        "name": name,
        "email": email,
        "message": message,
        "g-recaptcha-response": captcha
      })
    })
      .then(() => {
        setServerMessage("Bericht verstuurd.");
        setAlertClass('alert-success');
        setName("");
        setEmail("");
        setMessage("");
      })
      .catch(error => {
        setServerMessage("Bericht kon niet verstuurd worden.");
        setAlertClass('alert-danger');
      });

    setSending(false);
  } 

  return (
    <>
      <SEO title="Contact" description="Contact" />
      <div className="container">
        <form 
          name="contact" 
          method="POST"
          data-netlify-recaptcha="true"
          data-netlify="true"
          onSubmit={handleSubmit} 
          noValidate={true}
        >
          <div className="row mb-3">
            <div className="col-md-6">
              <h1 className="text-primary">Contact<span className="text-secondary">.</span></h1>
              <p>Heb je vragen of opmerkingen over de beweegroute of deze website? Of suggesties voor verbetering? Dan horen wij het graag.</p>
              <h2 className="text-primary h3">Route aanvragen</h2>
              <p>Wil je een route op papier (flyer) ontvangen? Laat dit dan weten via onderstaand contactformulier. We hebben je naam, postadres en gewenste route nodig.</p>
            </div>
          </div>
          <div className={`alert ${alertClass}`} aria-live="assertive">
          {serverMessage}
          </div>
          <div className="row">
            <div className="col-md-4">
              <TextField 
                type="text"
                placeholder="Naam"
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                autoComplete="name"
              />
              <TextField 
                type="email"
                placeholder="E-mailadres"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                autoComplete="email"
              />
            </div>
            <div className="col-md-8">
              <TextArea 
                placeholder="Bericht"
                id="message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
            </div>
          </div>
          <div className="d-md-flex justify-content-md-end mb-2">
            <ReCAPTCHA 
              sitekey={process.env.GATSBY_SITE_RECAPTCHA_KEY} 
              onChange={(value) => setCaptcha(value)}
              ref={recaptchaRef}
            />
          </div>
          <div className="d-md-flex justify-content-md-end">
            
            <SubmitButton 
              sending={sending}
            />
          </div>
        </form>
      </div>
    </>
  );
}

export default Contact;