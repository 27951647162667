import React from 'react'

export const formatDate = date => {
  var mthNames    = ["Januari","Februari","Maart","April","Mei","Juni","Juli","Augustus","September","October","November","December"];

  date            = new Date(date);
  var year        = "'" + date.getFullYear().toString().substr(-2);
  var datestring  = date.getDate() + ' ' + mthNames[date.getMonth()] + ' ' + year;

  return datestring;
}

export const isValidEmail = email => {
  const re = /\S+@\S+\.\S+/;

  if (!email || email.length <= 0) return false;
  if (!re.test(email)) return false;

  return true;
};

export const formatPrice = price => {
  price = Number.parseFloat(price);
  return (<span>&euro; {price.toLocaleString('nl-NL')}</span>);
}